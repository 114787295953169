import reportWebVitals from "./reportWebVitals";
import bootUtil from "./bootUtil";
import "./bootUtil.css";

async function doBoot() {
  try {
    const inExternalShell = "webHostDataProvider" in window;

    bootUtil.start(inExternalShell ? 2000 : 500);
    const main = await import("./reactMain");
    await main.boot();
  } catch (e) {
    console.error(e);
  } finally {
    bootUtil.done();
  }
}

doBoot().then();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
