const BootUtil = (function () {
  const BootUtil = {};

  var Settings = {
    minimum: 0.08,
    speed: 200,
  };

  BootUtil.positionUsing = "";

  BootUtil.delayTimer = null;

  BootUtil.status = null;

  BootUtil.set = function (n) {
    var started = typeof BootUtil.status === "number";
    n = clamp(n, Settings.minimum, 1);
    BootUtil.status = n === 1 ? null : n;
    var progress = BootUtil.render(!started),
      bar = progress.querySelector('[role="bar"]'),
      speed = Settings.speed;
    // eslint-disable-next-line
    progress.offsetWidth;
    queue(function (next) {
      if (BootUtil.positionUsing === "")
        BootUtil.positionUsing = BootUtil.getPositioningCSS();
      css(bar, barPositionCSS(n, speed));
      if (n === 1) {
        css(progress, { transition: "none", opacity: 1 });
        // eslint-disable-next-line
        progress.offsetWidth;
        setTimeout(function () {
          css(progress, {
            transition: "all " + speed + "ms linear",
            opacity: 0,
          });
          setTimeout(function () {
            BootUtil.remove();
            next();
          }, speed);
        }, speed);
      } else {
        setTimeout(next, speed);
      }
    });
    return this;
  };

  BootUtil.start = function (delayMs) {
    BootUtil.delayTimer = setTimeout(() => {
      if (!BootUtil.status) BootUtil.set(0);
      var work = function () {
        setTimeout(function () {
          if (!BootUtil.status) return;
          BootUtil.inc();
          work();
        }, 200);
      };
      work();
    }, delayMs);
    return this;
  };

  BootUtil.done = function (force) {
    if (BootUtil.delayTimer) {
      clearTimeout(BootUtil.delayTimer);
    }
    if (!force && !BootUtil.status) return this;
    return BootUtil.inc(0.3 + 0.5 * Math.random()).set(1);
  };

  BootUtil.inc = function (amount) {
    var n = BootUtil.status;
    if (!n) {
      return BootUtil.start();
    } else if (n > 1) {
      return;
    } else {
      if (typeof amount !== "number") {
        if (n >= 0 && n < 0.2) {
          amount = 0.1;
        } else if (n >= 0.2 && n < 0.5) {
          amount = 0.04;
        } else if (n >= 0.5 && n < 0.8) {
          amount = 0.02;
        } else if (n >= 0.8 && n < 0.99) {
          amount = 0.005;
        } else {
          amount = 0;
        }
      }
      n = clamp(n + amount, 0, 0.994);
      return BootUtil.set(n);
    }
  };

  BootUtil.render = function (fromStart) {
    if (!!document.querySelector("#progress"))
      return document.querySelector("#progress");
    var progress = document.createElement("div");
    progress.id = "progress";
    progress.innerHTML =
      '<div class="bar" role="bar"><div class="peg"></div></div>';
    var bar = progress.querySelector('[role="bar"]'),
      perc = fromStart ? "-100" : toBarPerc(BootUtil.status || 0),
      parent = document.body;
    css(bar, {
      transition: "all 0 linear",
      transform: "translate3d(" + perc + "%,0,0)",
    });
    parent.appendChild(progress);
    return progress;
  };

  BootUtil.remove = function () {
    var progress = document.querySelector("#progress");
    progress && removeElement(progress);
  };

  BootUtil.getPositioningCSS = function () {
    var bodyStyle = document.body.style;
    var vendorPrefix =
      "WebkitTransform" in bodyStyle
        ? "Webkit"
        : "MozTransform" in bodyStyle
          ? "Moz"
          : "msTransform" in bodyStyle
            ? "ms"
            : "OTransform" in bodyStyle
              ? "O"
              : "";
    if (vendorPrefix + "Perspective" in bodyStyle) {
      return "translate3d";
    } else if (vendorPrefix + "Transform" in bodyStyle) {
      return "translate";
    } else {
      return "margin";
    }
  };

  function clamp(n, min, max) {
    if (n < min) return min;
    if (n > max) return max;
    return n;
  }

  function toBarPerc(n) {
    return (-1 + n) * 100;
  }

  function barPositionCSS(n, speed) {
    var barCSS;
    if (BootUtil.positionUsing === "translate3d") {
      barCSS = { transform: "translate3d(" + toBarPerc(n) + "%,0,0)" };
    } else if (BootUtil.positionUsing === "translate") {
      barCSS = { transform: "translate(" + toBarPerc(n) + "%,0)" };
    } else {
      barCSS = { "margin-left": toBarPerc(n) + "%" };
    }
    barCSS.transition = "all " + speed + "ms linear";
    return barCSS;
  }

  var queue = (function () {
    var pending = [];

    function next() {
      var fn = pending.shift();
      if (fn) {
        fn(next);
      }
    }

    return function (fn) {
      pending.push(fn);
      if (pending.length == 1) next();
    };
  })();

  var css = (function () {
    var cssPrefixes = ["Webkit", "O", "Moz", "ms"],
      cssProps = {};

    function camelCase(string) {
      return string
        .replace(/^-ms-/, "ms-")
        .replace(/-([\da-z])/gi, function (match, letter) {
          return letter.toUpperCase();
        });
    }

    function getVendorProp(name) {
      var style = document.body.style;
      if (name in style) return name;
      var i = cssPrefixes.length,
        capName = name.charAt(0).toUpperCase() + name.slice(1),
        vendorName;
      while (i--) {
        vendorName = cssPrefixes[i] + capName;
        if (vendorName in style) return vendorName;
      }
      return name;
    }

    function getStyleProp(name) {
      name = camelCase(name);
      return cssProps[name] || (cssProps[name] = getVendorProp(name));
    }

    function applyCss(element, prop, value) {
      prop = getStyleProp(prop);
      element.style[prop] = value;
    }

    return function (element, properties) {
      var args = arguments,
        prop,
        value;
      if (args.length == 2) {
        for (prop in properties) {
          value = properties[prop];
          if (value !== undefined && properties.hasOwnProperty(prop))
            applyCss(element, prop, value);
        }
      } else {
        applyCss(element, args[1], args[2]);
      }
    };
  })();

  function removeElement(element) {
    element && element.parentNode && element.parentNode.removeChild(element);
  }

  return BootUtil;
})();

export default BootUtil;
